import React from "react";

interface ICardProps {
  title: string;
  body?: string;
}

const Card = ({ title, body }: ICardProps) => (
  <div>
    <div className="Title-Box">
      <h1>{title}</h1>
    </div>
    <div className="Content-Box">
      <p>{body}</p>
    </div>
  </div>
);

export default Card;
