import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";

import firebase from "firebase/app";
import "firebase/auth";

import { getParameterByName } from "../App";
import Loading from "./Loading";
import Card from "./Card";

const RecoverEmail = () => {
  const auth = firebase.auth();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");

  const handleRequestPasswordReset = () => {
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        alert(
          `Password reset email sent to ${email}. Please check your email.`
        );
      })
      .catch((err) => {
        alert(`Something went wrong. Please try again.`);
      });
  };

  useEffect(() => {
    setLoading(true);
    const actionCode = getParameterByName("oobCode");
    auth
      .checkActionCode(actionCode!)
      .then((info) => {
        setEmail(info.data.email!);
        return auth.applyActionCode(actionCode!);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setIsError(true);
      });
  }, [auth]);

  if (loading) {
    return <Loading />;
  }

  if (isError) {
    return <Card title="Something went wrong." body="Please try again." />;
  }

  return (
    <div>
      <div className="Title-Box">
        <h1>Email successfully restored</h1>
      </div>
      <div className="Content-Box">
        <p>
          Your email address <strong>{email}</strong> has been restored.
        </p>
      </div>
      <div className="Content-Box">
        <p>If you need to reset your password, request an email link below.</p>
      </div>
      <div className="Form-Button-Box">
        <Button color="primary" onClick={handleRequestPasswordReset}>
          Request password reset
        </Button>
      </div>
    </div>
  );
};

export default RecoverEmail;
