import React, { useState, useEffect, FormEvent } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import firebase from "firebase/app";
import "firebase/auth";

import { getParameterByName } from "../App";
import Loading from "./Loading";
import Card from "./Card";

const validatePassword = (string: string) => {
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
  return strongRegex.test(string);
};

const ResetPassword = () => {
  const auth = firebase.auth();
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const [success, setSuccess] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsPasswordValid(validatePassword(password));
    if (validatePassword(password)) {
      setLoading(true);
      const actionCode = getParameterByName("oobCode");
      auth
        .confirmPasswordReset(actionCode!, password)
        .then((resp) => {
          setLoading(false);
          setSuccess(true);
        })
        .catch((err) => {
          setLoading(false);
          alert(`Something went wrong: ${err.message}`);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    const actionCode = getParameterByName("oobCode");
    auth
      .verifyPasswordResetCode(actionCode!)
      .then((email) => {
        setLoading(false);
        setEmail(email);
      })
      .catch((err) => {
        setLoading(false);
        setExpired(true);
      });
  }, [auth]);

  if (loading) {
    return <Loading />;
  }

  if (expired) {
    return (
      <Card
        title="Try resetting your password again"
        body="Your request to reset your password has expired or the link has already been used"
      />
    );
  }

  if (success) {
    return (
      <Card
        title="Password successfully changed"
        body="You can now sign in with your new password"
      />
    );
  }

  return (
    <div>
      <div className="Title-Box">
        <h1>Reset your password</h1>
      </div>
      <div className="Content-Box">
        <p>
          for <strong>{email}</strong>
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="Form-Input-Box">
          <TextField
            type={showPassword ? "text" : "password"}
            value={password}
            placeholder="New password"
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            error={!isPasswordValid}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        {!isPasswordValid ? (
          <div className="Content-Box">
            <p style={{ fontSize: 12, color: "red" }}>
              Please enter at least one upper- and lowercase letter, number, and
              8 characters.
            </p>
          </div>
        ) : null}
        <div className="Form-Button-Box">
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
