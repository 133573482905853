import React, { useState, useEffect } from "react";

import firebase from "firebase/app";
import "firebase/auth";

import { getParameterByName } from "../App";
import Loading from "./Loading";
import Card from "./Card";

const VerifyEmail = () => {
  const auth = firebase.auth();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    setLoading(true);
    const actionCode = getParameterByName("oobCode");
    auth
      .applyActionCode(actionCode!)
      .then((resp) => {
        setLoading(false);
        setTitle("Email address verified");
        setBody("You can now close this tab.");
      })
      .catch((err) => {
        setLoading(false);
        setTitle("Try verifying your email again");
        setBody(
          "Your request to verify your email has expired or the link has already been used"
        );
      });
  }, [auth]);

  if (loading) {
    return <Loading />;
  }

  return <Card title={title} body={body} />;
};

export default VerifyEmail;
