import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";

import "./App.css";
import logo from "./loybase-logo.png";
import VerifyEmail from "./components/VerifyEmail";
import RecoverEmail from "./components/RecoverEmail";
import ResetPassword from "./components/ResetPassword";
import Card from "./components/Card";

firebase.initializeApp({
  apiKey: getParameterByName("apiKey"),
});

const App = () => {
  const [hasError, setHasError] = useState(false);

  const mode = getParameterByName("mode");
  const actionCode = getParameterByName("oobCode");

  useEffect(() => {
    if (!actionCode) {
      setHasError(true);
    }
  }, [actionCode]);

  useEffect(() => {
    try {
      firebase.auth();
    } catch (err) {
      setHasError(true);
    }
  }, []);

  return (
    <div className="App">
      <div className="Logo">
        <img className="Logo-Image" src={logo} alt="Logo" />
      </div>
      {hasError ? (
        <Card title="Invalid link" body="Please request a new action link." />
      ) : mode === "verifyEmail" ? (
        <VerifyEmail />
      ) : mode === "recoverEmail" ? (
        <RecoverEmail />
      ) : mode === "resetPassword" ? (
        <ResetPassword />
      ) : (
        <Card title="Invalid link" body="Please request a new action link." />
      )}
    </div>
  );
};

export default App;

// Utils
export function getParameterByName(name: keyof IURLParams) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
}

// Interfaces
interface IURLParams {
  mode: "resetPassword" | "recoverEmail" | "verifyEmail";
  oobCode: string;
  apiKey: string;
  continueUrl?: string;
  lang?: string;
}
