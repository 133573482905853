import { LinearProgress } from "@material-ui/core";
import React from "react";

const Loading = () => (
  <div className="App">
    <LinearProgress color="primary" />
  </div>
);

export default Loading;
